import { AsideStructure } from "@widgets/aside_with_content";
import styles from "./styles.module.css";
import { useEffect, useState } from "react";
import Stepper from "./Stepper";
import ChooseGender from "./ChooseGender";
import { ButtonFactory } from "@shared/ui/Button";
import Arrow from "@assets/arrow_long_white.svg";
import ChooseEthnicity from "./ChooseEthnicity";
import ChooseHeirStyle from "./ChooseHairStyle";
import ChooseBodyType from "./ChooseBodyType";
import ChoosePersonality from "./ChoosePersonality";
import { IIA, IPersonalityKey, ROUTES } from "@shared/interfaces";
import ChooseHobby from "./ChooseHobby";
import ChooseRelationship from "./ChooseRelationship";
import Summary from "./Summary";
import { useLocation, useNavigate } from "react-router-dom";
import { useCustomImageStore } from "@entities/customImage";
import { IUserState, useUserStore } from "@entities/user";
import { CustomGenerateImage } from "@widgets/generateImage";
import { useCountdown } from "@shared/hooks";
import { ReactComponent as Token } from "@shared/assets/tokenMd.svg";
import { Portal } from "@widgets/portal";
import { BecomePremium, BuyTokens } from "@widgets/subscription";
import * as amplitude from "@amplitude/analytics-browser";

const ESTIMATED_TIME = 60;
const GENERATION_COST = 10;

const CreateAi = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const user = useUserStore((state: IUserState) => state.user);
  const { setTokens } = useUserStore((state: IUserState) => state);
  const { createMyAi, customModels, getCustomModels } = useCustomImageStore();
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [isNoTokensPopUpOpen, setIsNoTokensPopUpOpen] = useState(false);
  const [isBecomePremiumPopUpOpen, setIsBecomePremiumPopUpOpen] = useState(false);

  const [validationError, setValidationError] = useState({});
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  const defaultKeyValue = { icon: "", value: "" };
  const isAuth = user.id && user.id !== "initializing";

  const urlParams = new URLSearchParams(window.location.search);
  const subId = urlParams.get("_subid");

  if (subId) {
    localStorage.setItem("subid", subId);
  }

  useEffect(() => {
    getCustomModels();
  }, []);

  useEffect(() => {
    const savedAiString = localStorage.getItem("createdAi");
    const savedAiProcessing = localStorage.getItem("createAiProcessing");

    if (savedAiProcessing) {
      setCurrentStep(8);
      return;
    }

    if (!savedAiString) {
      return;
    }

    const savedAi = JSON.parse(savedAiString);

    if (savedAi?.ai?.isFinish) {
      setCurrentStep(7);
      setAi({ ...savedAi?.ai });
    }
  }, []);

  useEffect(() => {
    document.getElementById("root")?.children[0]?.scrollTo(0, 0);
  }, [currentStep]);

  const [ai, setAi] = useState<IIA>({
    age: "0",
    gender: "girls",
    hobbies: [],
    isReady: false,
    occupation: "",
    hair_color: "",
    isFinish: false,
    ethnicity: defaultKeyValue,
    butt_size: defaultKeyValue,
    body_type: defaultKeyValue,
    hair_style: defaultKeyValue,
    eyes_color: defaultKeyValue,
    breast_type: defaultKeyValue,
    personality: defaultKeyValue,
    relationship: defaultKeyValue,
  });

  const setHobby = (hobby: string) => {
    setValidationError({ hobby: false });
    const existedHobby = ai.hobbies.find((item) => item === hobby);

    if (existedHobby) {
      const filteredHobby = ai.hobbies.filter((item) => item !== existedHobby);
      setAi({ ...ai, hobbies: filteredHobby });
      return;
    }

    if (ai.hobbies.length === 3) {
      return;
    }

    setAi({ ...ai, hobbies: [...ai.hobbies, hobby] });
  };

  const setPersonalityValue = (value: any) => {
    setValidationError({ [value]: false });
    setAi({ ...ai, ...value });
  };

  const renderContent = () => {
    switch (currentStep) {
      case 0:
        return (
          <ChooseGender
            gender={ai.gender}
            genderChoose={(gender: string) => setAi({ ...ai, gender })}
            validationError={validationError}
          />
        );
      case 1:
        return (
          <ChooseEthnicity
            age={ai.age}
            eye={ai.eyes_color}
            ethnicity={ai.ethnicity}
            validationError={validationError}
            ethnicityChoose={(ethnicity: IPersonalityKey) => setPersonalityValue({ ethnicity })}
            ageChoose={(age: string) => setPersonalityValue({ age })}
            eyeChoose={(eyes_color: IPersonalityKey) => setPersonalityValue({ eyes_color })}
          />
        );
      case 2:
        return (
          <ChooseHeirStyle
            hair={ai.hair_style}
            hairColor={ai.hair_color}
            validationError={validationError}
            hairChoose={(hair_style: IPersonalityKey) => setPersonalityValue({ hair_style })}
            hairColorChoose={(hair_color: string) => setPersonalityValue({ hair_color })}
          />
        );
      case 3:
        return (
          <ChooseBodyType
            bodyType={ai.body_type}
            breastSize={ai.breast_type}
            butt={ai.butt_size}
            validationError={validationError}
            buttTypeChoose={(butt_size: IPersonalityKey) => setPersonalityValue({ butt_size })}
            bodyTypeChoose={(body_type: IPersonalityKey) => setPersonalityValue({ body_type })}
            breastSizeChoose={(breast_type: IPersonalityKey) => setPersonalityValue({ breast_type })}
          />
        );
      case 4:
        return (
          <ChoosePersonality
            personality={ai.personality}
            validationError={validationError}
            personalityChoose={(personality: any) => setPersonalityValue({ personality })}
          />
        );
      case 5:
        return (
          <ChooseHobby
            occupation={ai.occupation}
            hobbies={ai.hobbies}
            validationError={validationError}
            hobbyChoose={(hobby: string) => setHobby(hobby)}
            occupationChoose={(occupation: any) => setPersonalityValue({ occupation })}
          />
        );
      case 6:
        return (
          <ChooseRelationship
            relationship={ai.relationship}
            validationError={validationError}
            relationShipChoose={(relationship: any) => setPersonalityValue({ relationship })}
          />
        );
      case 7:
        return <Summary ai={ai} />;
      case 8:
        return (
          <div className={styles.create_ai_generation}>
            <CustomGenerateImage subText="Generation is in progress…" />
          </div>
        );
    }
  };

  const createAi = async () => {
    window.localStorage.setItem(
      "createdAi",
      JSON.stringify({
        ai: { ...ai, isFinish: true },
      })
    );

    amplitude.track(`Bring my AI to life Button Clicked`, {
      userType: user.plan,
    });

    if (isAuth) {
      if (customModels.length === 1 && user.plan === "free") {
        return setIsBecomePremiumPopUpOpen(true);
      }

      setIsSubmitDisabled(true);
      const res: any = await createMyAi(ai);

      setIsSubmitDisabled(false);

      if (!res) {
        return;
      }

      if (res?.error === "insufficient_balance" && res?.type === "free_limit") {
        return setIsBecomePremiumPopUpOpen(true);
      }

      if (res?.error === "insufficient_balance" && res?.type === "insufficient_tokens") {
        return setIsNoTokensPopUpOpen(true);
      }

      if (res.model_id) {
        if (res?.current_tokens) {
          setTokens(res.current_tokens);
        }

        localStorage.setItem("aiProcessingId", res.model_id);
        setCurrentStep(8);
      }

      return;
    }

    localStorage.setItem("returnUrl", pathname);

    navigate(`${ROUTES.REGISTER}?source=generate_image`);
    return;
  };

  const renderBadge = () => {
    if (currentStep !== 7) {
      return;
    }

    if (user.plan !== "premium") {
      return <div className={styles.create_ai_badge}>Free Trial</div>;
    }

    return (
      <span className={styles.generate_button__ribbon}>
        <Token />
        <span>{GENERATION_COST} Tokens</span>
      </span>
    );
  };

  const nextStep = (currentStep: number) => {
    if (currentStep === 1) {
      if (!ai.ethnicity.value) {
        return setValidationError({ ethnicity: true });
      }

      if (ai.age === "0") {
        return setValidationError({ age: true });
      }

      if (!ai.eyes_color.value) {
        return setValidationError({ eyes_color: true });
      }
    }

    if (currentStep === 2) {
      if (!ai.hair_style.value) {
        return setValidationError({ hair_style: true });
      }
      if (!ai.hair_color) {
        return setValidationError({ hair_color: true });
      }
    }

    if (currentStep === 3) {
      if (!ai.body_type.value) {
        return setValidationError({ body_type: true });
      }
      if (!ai.breast_type.value) {
        return setValidationError({ breast_type: true });
      }
      if (!ai.butt_size.value) {
        return setValidationError({ butt_size: true });
      }
    }

    if (currentStep === 4) {
      if (!ai.personality.value) {
        return setValidationError({ personality: true });
      }
    }

    if (currentStep === 5) {
      if (!ai.occupation) {
        return setValidationError({ occupation: true });
      }

      if (!ai.hobbies.length) {
        return setValidationError({ hobbies: true });
      }
    }

    if (currentStep === 6) {
      if (!ai.relationship.value) {
        return setValidationError({ relationship: true });
      }
    }

    setValidationError({});

    amplitude.track(`Constructor Clicked Next`, {
      userType: user.plan,
    });

    setCurrentStep(currentStep + 1);
  };

  const definedDisabled = () => {
    if (currentStep === 1 && ai.ethnicity && ai.age !== "0" && ai.eyes_color.value) {
      return true;
    }

    if (currentStep === 2 && ai.hair_style.value && ai.hair_color) {
      return true;
    }

    if (currentStep === 3 && ai.body_type.value && ai.breast_type.value && ai.butt_size.value) {
      return true;
    }

    if (currentStep === 4 && ai.personality.value) {
      return true;
    }

    if (currentStep === 5 && ai.occupation && ai.hobbies.length) {
      return true;
    }

    if (currentStep === 6 && ai.relationship.value) {
      return true;
    }

    if (currentStep === 7 && Object.keys(ai).length >= 15) {
      return true;
    }

    return false;
  };

  return (
    <div className={styles.create_ai_content}>
      <AsideStructure>
        {isNoTokensPopUpOpen && (
          <Portal className="portal_home">
            <BuyTokens onClose={() => setIsNoTokensPopUpOpen(false)} source={pathname} action="Generate Character" />
          </Portal>
        )}

        {isBecomePremiumPopUpOpen && (
          <Portal className="portal_home">
            <BecomePremium onClose={() => setIsBecomePremiumPopUpOpen(false)} />
          </Portal>
        )}

        <h1 className={styles.create_ai_title}>
          Create <span>my AI</span>
        </h1>

        {currentStep <= 7 && <Stepper step={currentStep} />}

        <div className={styles.children_wrapper}>
          <div>{renderContent()}</div>
          <div
            className={`
              ${styles.ai_nav} 
              ${currentStep === 1 ? styles.first : ""}
              ${currentStep === 7 ? styles.last : ""}
              `}
          >
            {currentStep !== 1 && currentStep <= 7 && (
              <ButtonFactory
                variant="primary"
                label=""
                type="button"
                onClick={() => setCurrentStep(currentStep - 1)}
                children={
                  <div className={styles.prev_btn}>
                    <img src={Arrow} alt="arrow" />
                    <span>Previous</span>
                  </div>
                }
              />
            )}

            {currentStep <= 7 && (
              <div className={`${styles.create_ai_btn_container} ${!definedDisabled() ? styles.step_opacity : ""}`}>
                {renderBadge()}
                <ButtonFactory
                  variant="danger"
                  label="Previous"
                  type="button"
                  disabled={isSubmitDisabled}
                  onClick={() => (currentStep !== 7 ? nextStep(currentStep) : createAi())}
                  children={
                    <div className={`${currentStep === 7 ? styles.final_step : styles.next_btn}`}>
                      <span>{currentStep !== 7 ? "Next" : "Bring my AI to life"}</span>
                      <img src={Arrow} alt="arrow" />
                    </div>
                  }
                />
              </div>
            )}
          </div>
        </div>
      </AsideStructure>
    </div>
  );
};

export default CreateAi;
