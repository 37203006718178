import { IUserState, useUserStore } from "@entities/user";
import styles from "./styles_hobby.module.css";
import * as amplitude from "@amplitude/analytics-browser";

interface IProps {
  occupation: string;
  hobbies: string[];
  occupationChoose: (data: string) => void;
  hobbyChoose: (data: string) => void;
  validationError: any;
}

interface IOccupation {
  title: string;
  value: string;
}

const OCCUPATION: IOccupation[] = [
  {
    title: "Massage Therapist",
    value: "Massage Therapist",
  },
  {
    title: "Dentist",
    value: "Dentist",
  },
  {
    title: "Nutritionist",
    value: "Nutritionist",
  },
  {
    title: "Fitness Coach",
    value: "Fitness Coach",
  },
  {
    title: "Artist",
    value: "Artist",
  },
  {
    title: "Pharmacist",
    value: "Pharmacist",
  },
  {
    title: "Hairdresser",
    value: "Hairdresser",
  },
  {
    title: "Makeup Artist",
    value: "Makeup Artist",
  },
  {
    title: "Gynecologist",
    value: "Gynecologist",
  },
  {
    title: "Writer",
    value: "Writer",
  },
  {
    title: "Secretary",
    value: "Secretary",
  },
  {
    title: "Librarian",
    value: "Librarian",
  },
  {
    title: "Social Worker",
    value: "Social Worker",
  },
  {
    title: "Fashion Designer",
    value: "Fashion Designer",
  },
  {
    title: "Model",
    value: "Model",
  },
  {
    title: "Police officer",
    value: "Police officer",
  },
  {
    title: "Nurse",
    value: "Nurse",
  },
  {
    title: "Yoga Instructor",
    value: "Yoga Instructor",
  },
  {
    title: "Waitress",
    value: "Waitress",
  },
  {
    title: "Cook",
    value: "Cook",
  },
  {
    title: "Unemployed",
    value: "Unemployed",
  },
  {
    title: "Dancer",
    value: "Dancer",
  },
  {
    title: "Teacher",
    value: "Teacher",
  },
  {
    title: "Student",
    value: "Student",
  },
  {
    title: "Life Coach",
    value: "Life Coach",
  },
  {
    title: "Astronaut",
    value: "Astronaut",
  },
  {
    title: "Kindergarten Teacher",
    value: "Kindergarten Teacher",
  },
  {
    title: "Soldier/Military Personnel",
    value: "Soldier/Military Personnel",
  },
];

const HOBBY: IOccupation[] = [
  {
    title: "Yoga",
    value: "Yoga",
  },
  {
    title: "Vlogging",
    value: "Vlogging",
  },
  {
    title: "Fitness",
    value: "Fitness",
  },
  {
    title: "Hiking",
    value: "Hiking",
  },
  {
    title: "Traveling",
    value: "Traveling",
  },
  {
    title: "Dancing",
    value: "Dancing",
  },
  {
    title: "Gaming",
    value: "Gaming",
  },
  {
    title: "Parties",
    value: "Parties",
  },
  {
    title: "Rock",
    value: "Rock",
  },
  {
    title: "Series",
    value: "Series",
  },
  {
    title: "Anime",
    value: "Anime",
  },
  {
    title: "Cosplay",
    value: "Cosplay",
  },
  {
    title: "Art",
    value: "Art",
  },
  {
    title: "Self-Development",
    value: "Self-Development",
  },
  {
    title: "Diy Crafting",
    value: "Diy Crafting",
  },
  {
    title: "Veganism",
    value: "Veganism",
  },
  {
    title: "Photography",
    value: "Photography",
  },
  {
    title: "Volunteering",
    value: "Volunteering",
  },
  {
    title: "Cars",
    value: "Cars",
  },
  {
    title: "Watching Netflix",
    value: "Watching Netflix",
  },
  {
    title: "Martial Arts",
    value: "Martial Arts",
  },
];

const ChooseHobby = ({ hobbies, occupation, hobbyChoose, occupationChoose, validationError }: IProps) => {
  const user = useUserStore((state: IUserState) => state.user);

  const defineActive = (value: string) => {
    return hobbies.find((item) => item === value);
  };

  const onOccupationChoose = (value: string) => {
    amplitude.track(`Constructor Occupation Selected`, {
      userType: user.plan,
      parameters: [value],
    });

    occupationChoose(value);
  };

  const onHobbyChoose = (value: any) => {
    amplitude.track(`Constructor Hobbies Selected`, {
      userType: user.plan,
      parameters: [value],
    });

    hobbyChoose(value);
  };

  return (
    <div>
      <div className={`${validationError?.occupation && styles.createAi_error}`}>
        <h4 className={styles.occupation_title}> Choose Occupation</h4>
        <div className={styles.hobby_container}>
          {OCCUPATION.map((item, index) => (
            <OccupationItem
              key={index}
              item={item}
              active={occupation}
              hobbyChoose={(value) => onOccupationChoose(value)}
            />
          ))}
        </div>
      </div>

      <div className={`${validationError?.hobbies && styles.createAi_error}`}>
        <h4 className={styles.hobby_title}> Choose Hobbies</h4>
        <h6 className={styles.hobby_subtitle}> You can choose up to 3</h6>

        <div className={styles.hobby_container}>
          {HOBBY.map((item, index) => (
            <OccupationItem
              key={index}
              item={item}
              hobbyChoose={(value) => onHobbyChoose(value)}
              active={defineActive(item.value)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

interface IPersonalityProps {
  item: IOccupation;
  active: string | undefined;
  hobbyChoose: (data: string) => void;
}

const OccupationItem = ({ item, active, hobbyChoose }: IPersonalityProps) => {
  return (
    <div
      className={`${styles.hobby_item} ${active === item.value ? styles.hobby_active : ""}`}
      onClick={() => hobbyChoose(item.value)}
    >
      <h6 className={styles.hobby_text}>{item.title}</h6>
    </div>
  );
};

export default ChooseHobby;
