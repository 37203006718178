import { useEffect } from "react";
import styles from "./styles_summary.module.css";
import { IIA } from "@shared/interfaces";
import * as amplitude from "@amplitude/analytics-browser";

interface IProps {
  ai: IIA;
}

const Summary = ({ ai }: IProps) => {
  useEffect(() => {
    amplitude.track(`Constructor Summary Shown`);
  }, []);

  const renderAi = () => {
    const orderingElement = [
      { title: "Ethnicity", key: "ethnicity" },
      {
        title: "Age",
        key: "age",
      },
      {
        title: "Eyes Color",
        key: "eyes_color",
      },
      {
        title: "Hair Style",
        key: "hair_style",
      },
      {
        title: "Hair Color",
        key: "hair_color",
      },
      {
        title: "Body Type",
        key: "body_type",
      },
      {
        title: "Breast Size",
        key: "breast_type",
      },
      {
        title: "Butt Size",
        key: "butt_size",
      },
      {
        title: "Personality",
        key: "personality",
      },
      {
        title: "Relationship",
        key: "relationship",
      },
    ];

    return orderingElement.map((item, index) => (
      <AiItem key={item.key} aikey={item} value={ai[item.key as keyof IIA]} />
    ));
  };

  return (
    <div>
      <h4 className={styles.summary_title}>Summary</h4>
      <div className={styles.summary_container}>{renderAi()}</div>
      <div className={styles.summary_hobbies}>
        {ai.occupation && (
          <div className={styles.summary_hobby}>
            <h6 className={styles.summary_item_title}>Occupation</h6>
            {ai.occupation && <HobbyItem item={ai.occupation} />}
          </div>
        )}
        {ai.hobbies.length && (
          <div className={`${styles.summary_hobby} ${styles.summary_items}`}>
            <h6 className={styles.summary_item_title}>Hobbies</h6>
            {ai.hobbies.map((item, index) => (
              <HobbyItem key={index} item={item} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

interface IAiItemProps {
  aikey: any;
  value: any;
}

const AiItem = ({ aikey, value }: IAiItemProps) => {
  if (!value) {
    return <></>;
  }

  const modifyAge = (value: string) => {
    if (value === "18") {
      return `Teen(18+)`;
    }
    return value;
  };

  if (aikey.key === "age" && value !== "0") {
    return (
      <div className={styles.summary_item_container}>
        <h6 className={styles.summary_item_title}>{aikey.title}</h6>
        <div className={`${styles.summary_item}`}>
          <h5>{modifyAge(value)}</h5>
        </div>
      </div>
    );
  }

  if (aikey.key === "hair_color") {
    return (
      <div className={styles.summary_item_container}>
        <h6 className={styles.summary_item_title}>{aikey.title}</h6>
        <div className={`${styles.summary_item} ${styles[value]}`}>
          <div className={styles.summary_item_badge}>{value}</div>
        </div>
      </div>
    );
  }

  if (!value?.icon) {
    return <></>;
  }

  if (aikey.key === "personality") {
    return (
      <div className={styles.summary_item_container}>
        <h6 className={styles.summary_item_title}>{aikey.title}</h6>
        <div className={`${styles.summary_item}`}>
          <div className={styles.summary_item_img}>
            <img src={value.icon} alt={value.title} />
            {value.value && <div className={styles.summary_item_badge}>{value.value}</div>}
          </div>
        </div>
      </div>
    );
  }

  if (aikey.key === "relationship") {
    return (
      <div className={styles.summary_item_container}>
        <h6 className={styles.summary_item_title}>{aikey.title}</h6>
        <div className={`${styles.summary_item} ${styles.summary_relationship}`}>
          <img src={value.icon} alt={value.title} />
          {value.value && <div className={styles.summary_item_badge}>{value.value}</div>}
        </div>
      </div>
    );
  }

  return (
    <div className={styles.summary_item_container}>
      <h6 className={styles.summary_item_title}>{aikey.title}</h6>
      <div className={`${styles.summary_item}`}>
        {value?.icon && (
          <div className={styles.summary_wrapper}>
            <img src={value.icon} alt={value.title} />
          </div>
        )}
        {value?.icon && <div className={styles.summary_item_badge}>{value.value}</div>}
      </div>
    </div>
  );
};

interface IHobbyItemProps {
  item: string;
}

const HobbyItem = ({ item }: IHobbyItemProps) => {
  return <div className={styles.summary_hobby_item}>{item}</div>;
};

export default Summary;
