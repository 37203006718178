import styles from "./styles.module.css";
import { ROUTES, ROUTES_QUERY } from "@shared/interfaces";
import Explore from "@assets/aside/explore.svg";
import ExploreActive from "@assets/aside/explore_active.svg";
import Chat from "@assets/aside/chat.svg";
import ChatActive from "@assets/aside/chat_active.svg";
import Gallery from "@assets/aside/gallery.svg";
import GalleryActive from "@assets/aside/gallery_active.svg";
import Subscription from "@assets/aside/brilliant.svg";
import SubscriptionActive from "@assets/aside/brilliant_active.svg";
import GenerateImage from "@assets/aside/generate_image.svg";
import GenerateImageActive from "@assets/aside/generate.svg";
import Token from "@assets/tokenMd.svg";
import { useLocation, useParams } from "react-router-dom";
import { IUserState, useUserStore } from "@entities/user";
import { IAsideNavItem } from "@widgets/aside";
import { Link } from "react-router-dom";
import CreateAi from "@assets/aside/create_ai.svg";
import CreateAiActive from "@assets/aside/create_ai_active.svg";

const FooterNav = () => {
  const { pathname } = useLocation();
  const { id } = useParams();
  const user = useUserStore((state: IUserState) => state.user);

  const ASIDE_NAV: IAsideNavItem[] = [
    {
      path: `${ROUTES.HOME}?type=${ROUTES_QUERY.female}`,
      title: "Explore",
      icon: Explore,
      activeIcon: ExploreActive,
    },
    {
      path: `${ROUTES.GALLERY}?type=${ROUTES_QUERY.female}`,
      title: "Gallery",
      icon: Gallery,
      activeIcon: GalleryActive,
    },
    // {
    //   path: ROUTES.GENERATE_IMAGE,
    //   title: "Generate",
    //   icon: GenerateImage,
    //   activeIcon: GenerateImageActive,
    // },
    {
      path: ROUTES.CREATE_AI,
      title: "Create",
      icon: CreateAi,
      activeIcon: CreateAiActive,
    },
    {
      path: ROUTES.CHAT,
      title: "Chat",
      icon: Chat,
      activeIcon: ChatActive,
    },

    {
      path: user.plan === "premium" ? ROUTES.SUBSCRIBE : ROUTES.PLANS,
      title: user.plan === "premium" ? "Buy Tokens" : "Premium",
      icon: user.plan === "premium" ? Token : Subscription,
      activeIcon: user.plan === "premium" ? Token : SubscriptionActive,
    },
  ];

  const isPathActive = (path: string): boolean => {
    const getKeyPath = pathname.split("/")[1];
    const isHomePage = getKeyPath === "";

    if (isHomePage && path.includes(ROUTES.HOME)) {
      return true;
    }

    const exploreRoute = `${ROUTES.HOME}?type=${ROUTES_QUERY.female}`;
    if (path === exploreRoute && "/" + getKeyPath === ROUTES.SETTINGS) {
      return true;
    }

    return !isHomePage && path.includes(getKeyPath);
  };

  const isChatUrl = pathname.split("/");
  const isChatMobilePage = isChatUrl[1] === "chat";

  if (isChatMobilePage && id) {
    return <></>;
  }

  return (
    <ul className={styles.footer_nav_container}>
      {ASIDE_NAV.map((nav: IAsideNavItem) => (
        <Link
          key={nav.title}
          to={nav.path}
          className={`${styles.footer_nav_link_wrapper} ${isPathActive(nav.path) && styles.footer_nav_link_active} `}
        >
          <div className={styles.footer_nav_link_img_container}>
            <img src={isPathActive(nav.path) ? nav.activeIcon : nav.icon} alt={`aside_${nav.title}`} />
            <span>{nav.title}</span>
          </div>
        </Link>
      ))}
    </ul>
  );
};

export default FooterNav;
