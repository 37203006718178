import { useEffect, useState } from "react";
import { CircleProgress } from "@shared/ui/CircleProgress";
import styles from "./CustomGenerateImage.module.css";
import useInterval from "./useInterval";
import { IModelState } from "@entities/model/model/model.types";
import { useModelStore } from "@entities/model/model/model.store";
import { ROUTES } from "@shared/interfaces";
import { useNavigate } from "react-router-dom";

const ESTIMATED_TIME = 60;

type Props = {
  subText?: string;
};

const CustomGenerateImage = ({ subText }: Props) => {
  const { percentage } = useInterval();
  const navigate = useNavigate();
  const { checkAiGenerationStatus } = useModelStore((state: IModelState) => state);

  useEffect(() => {
    const fetchData = async () => {
      const aiProcessingId = localStorage.getItem("aiProcessingId");

      if (aiProcessingId) {
        const response: any = await checkAiGenerationStatus(aiProcessingId);

        if (response.status === "ready") {
          localStorage.removeItem("createAiProcessing");
          localStorage.removeItem("aiProcessingId");
          localStorage.removeItem("createdAi");

          navigate(`${ROUTES.MY_AIS}/${response.id}`);
        }
      }
    };

    fetchData();
  }, []);

  return (
    <div className={styles.result}>
      <div className={styles.progress}>
        <div className={styles.progress__loader}>
          <CircleProgress percentage={percentage >= 99 ? 99 : percentage} />
        </div>
        <span className={styles.progress__title}>Please wait!</span>
        <p className={styles.progress__subtitle}>{subText}</p>
      </div>
    </div>
  );
};

export default CustomGenerateImage;
