import { useEffect, useState } from "react";

const useInterval = () => {
  let interval: any;
  const currentPercentage = localStorage.getItem("createAiProcessing") || 0;
  const [percentage, setPercentage] = useState(+currentPercentage);

  useEffect(() => {
    clearInterval(interval);
    interval = null;

    interval = setInterval(() => {
      if (percentage + 1 > 99) {
        clearInterval(interval);

        return;
      }

      setPercentage((percentage) => {
        return percentage + 1;
      });
    }, 600);

    return () => {
      clearInterval(interval);
      interval = null;
    };
  }, []);

  const nextPercentage = percentage;

  if (nextPercentage < 100) {
    localStorage.setItem("createAiProcessing", nextPercentage.toString());
  }

  return { percentage };
};

export default useInterval;
