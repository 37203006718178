import { SubscriptionBanner } from "@widgets/subscription";
import styles from "./styles.module.css";
import { AsideStructure } from "@widgets/aside_with_content";
import { HomeGallery } from "@widgets/home";
import { IUserState, useUserStore } from "@entities/user";
import Footer from "@widgets/home/ui/HomeFooter";
import { useModelStore } from "@entities/model/model/model.store";
import { IModelState } from "@entities/model/model/model.types";

const HomePage: React.FC = () => {
  const plan = useUserStore((state: IUserState) => state.user.plan);
  const models = useModelStore((state: IModelState) => state.models);

  const defineCondition = () => {
    return !plan || plan === "free";
  };

  return (
    <div className={styles.home_container}>
      <AsideStructure>
        <div className={styles.home_content}>{defineCondition() && <SubscriptionBanner />}</div>
        <div className={styles.home_gallery}>
          <HomeGallery />
        </div>
        {models.length && <Footer />}
      </AsideStructure>
    </div>
  );
};

export default HomePage;
