import { InputItem } from "@shared/ui/Inputs";
import styles from "./styles.module.css";

import Arrow from "@assets/typing_arrow.svg";
import { ButtonFactory } from "@shared/ui/Button";
import { IFormMessageState } from "@widgets/auth/loginForm";
import { AskMe } from "../ask";
import { useEffect, useState } from "react";
import { AskToGenerate } from "../askToGenerateBunner";
import { useModelStore } from "@entities/model/model/model.store";
import { IModelState } from "@entities/model/model/model.types";
import { useParams } from "react-router-dom";
import { GetPremiumBanner } from "@widgets/getPremiumBanner";
import { IUserState, useUserStore } from "@entities/user";
import moment from "moment";
import * as amplitude from "@amplitude/analytics-browser";

interface IProps {
  showHowToUse: () => void;
  formData: IFormMessageState;
  onChangeInput: (e: any) => void;
  onSubmitForm: (e: React.FormEvent) => void;
}

const messageState: IFormMessageState = {
  message: "",
};

const Typing: React.FC<IProps> = ({ formData, onSubmitForm, showHowToUse, onChangeInput }: IProps) => {
  const [isAskToGenerateShow, setAskToGenerateShow] = useState<boolean>(false);
  const [isSubscriptionBannerShow, setSubscriptionBannerShow] = useState<boolean>(false);
  const messages = useModelStore((state: IModelState) => state.messages);
  const disabledBtn = useModelStore((state: IModelState) => state.disabledBtn);
  let { id } = useParams();
  const user = useUserStore((state: IUserState) => state.user);
  const { model }: any = useModelStore((state: IModelState) => state.modelActive);

  useEffect(() => {
    if (localStorage.getItem("ask_to_generate") === "1") {
      return;
    }
    if (messages && messages?.length > 2) {
      setAskToGenerateShow(true);
    }

    return () => {
      setAskToGenerateShow(false);
    };
  }, [messages?.length, id]);

  useEffect(() => {
    const isSubscriptionBannerShowCondition =
      localStorage.getItem("ask_to_generate") === "1" &&
      (user.plan === "free" || user.plan === "") &&
      getShowBannerTime();

    if (isSubscriptionBannerShowCondition) {
      amplitude.track(`Premium Tooltip for Image Generation Shown`, {
        characterId: model.id,
        characterName: model.name,
      });

      setSubscriptionBannerShow(true);
    }
  }, [id]);

  const getShowBannerTime = () => {
    const TIME_GAP = 15;
    const time = localStorage.getItem("subscribe_to_generate");
    if (!time) {
      return true;
    }

    const currentDate = moment().format();

    if (moment.utc(currentDate, "HH:mm:ss").diff(moment.utc(time, "HH:mm:ss"), "minutes") >= TIME_GAP) {
      return true;
    }

    return false;
  };

  const defineVariant = () => {
    if (formData.message.length) {
      return "danger";
    }

    return "secondary";
  };

  const defineDisableBtn = () => {
    if (disabledBtn) {
      return `${styles.typing_container} ${styles.typing_container_disabled}`;
    }

    return styles.typing_container;
  };

  const parseMessage = (message: string) => {
    if (
      message.includes("Send") ||
      message.includes("send") ||
      message.includes("Show") ||
      message.includes("show") ||
      message.includes("see") ||
      message.includes("See")
    ) {
      return true;
    }
  };

  const checkBeforeSubmit = (e: any) => {
    e.preventDefault();

    if (localStorage.getItem("ask_to_generate") === "1") {
      const currentTime = moment().format();

      localStorage.setItem("subscribe_to_generate", currentTime);
      setSubscriptionBannerShow(false);
    }

    const value = e.target[0].value;
    if (parseMessage(value)) {
      setAskToGenerateShow(false);
      localStorage.setItem("ask_to_generate", "1");

      amplitude.track(`Image Generation Command Sent`, {
        tokensUsed: 2,
        characterId: model.id,
        characterName: model.name,
        userType: user?.plan || "free",
      });
    }

    onSubmitForm(e);
  };

  const onSendMessage = (e: any) => {
    const value = e.target.value;

    if (parseMessage(value)) {
      setAskToGenerateShow(false);
      localStorage.setItem("ask_to_generate", "1");
    }

    amplitude.track(`Image Generation Command Selected`, {
      characterId: model.id,
      characterName: model.name,
      userType: user?.plan || "free",
    });

    onChangeInput(e);
  };

  return (
    <form className={defineDisableBtn()} onSubmit={checkBeforeSubmit}>
      {isAskToGenerateShow && (
        <div className={styles.ask_gen}>
          <AskToGenerate />
        </div>
      )}

      {isSubscriptionBannerShow && (
        <div className={styles.premium_banner}>
          <GetPremiumBanner />
        </div>
      )}

      <InputItem
        type="text"
        name="message"
        onChange={onChangeInput}
        value={formData.message}
        placeholder="Type message"
      />
      <ButtonFactory label="" type="submit" variant={defineVariant()} children={<img src={Arrow} alt="submit_btn" />} />
      <div className={styles.typing_askme}>
        <AskMe
          showHowToUse={showHowToUse}
          onChangeInput={(message: string) => onSendMessage({ target: { name: "message", value: message } })}
        />
      </div>
    </form>
  );
};

export default Typing;
