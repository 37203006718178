import { ModelMessageIcon } from "./ui/ModelMessageIcon";
import { ModelMessageImg } from "./ui/ModelMessageImg/index";
import { ModelMessageLottie } from "./ui/ModelMessageLottie";
import { ModelMessageText } from "./ui/ModelMessageText";

interface IProps {
  variant?: "text" | "icon" | "img" | "lottie";
  type?: string;
  text?: string;
  icon?: React.ReactElement;
  img?: string;
  id?: string;
  chatId?: any;
  isBlured?: boolean;
}

const MessageItemFactory = ({ variant, type, text = "", icon = <></>, img, isBlured, ...props }: IProps) => {
  switch (variant) {
    case "lottie":
      return <ModelMessageLottie type={type} icon={icon} />;

    case "icon":
      return <ModelMessageIcon type={type} icon={icon} />;

    case "img":
      return <ModelMessageImg type={type} img={img} isBlured={isBlured} />;

    case "text":
    default:
      return <ModelMessageText type={type} text={text} {...props} />;
  }
};

export default MessageItemFactory;
