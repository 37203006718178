import styles from "./styles_relationship.module.css";

import Stranger from "@assets/constructor/ethnicity/rel_stranger.svg";
import SchoolMate from "@assets/constructor/ethnicity/rel_schoolmate.svg";
import Colleague from "@assets/constructor/ethnicity/rel_colleague.svg";
import Mentor from "@assets/constructor/ethnicity/rel_mentor.svg";
import Girlfriend from "@assets/constructor/ethnicity/rel_girlfriend.svg";
import SexFriend from "@assets/constructor/ethnicity/rel_sex_friend.svg";
import Wife from "@assets/constructor/ethnicity/rel_wife.svg";
import Mistress from "@assets/constructor/ethnicity/rel_mistress.svg";
import Friend from "@assets/constructor/ethnicity/rel_friend.svg";
import BestFriend from "@assets/constructor/ethnicity/rel_best_friend.svg";

import StepSister from "@assets/constructor/ethnicity/rel_step_sister.svg";
import StepMom from "@assets/constructor/ethnicity/rel_step_mom.svg";
import { IPersonalityKey } from "@shared/interfaces";
import * as amplitude from "@amplitude/analytics-browser";
import { IUserState, useUserStore } from "@entities/user";

interface IProps {
  relationship: IPersonalityKey;
  validationError: any;
  relationShipChoose: (data: IPersonalityKey) => void;
}

interface IRelationship {
  title: string;
  value: string;
  icon?: string;
}

const RELATIONSHIPS: IRelationship[] = [
  {
    title: "Stranger",
    value: "Stranger",
    icon: Stranger,
  },
  {
    title: "School Mate",
    value: "School Mate",
    icon: SchoolMate,
  },
  {
    title: "Colleague",
    value: "Colleague",
    icon: Colleague,
  },
  {
    title: "Mentor",
    value: "Mentor",
    icon: Mentor,
  },
  {
    title: "Girlfriend",
    value: "Girlfriend",
    icon: Girlfriend,
  },
  {
    title: "Sex Friend",
    value: "Sex Friend",
    icon: SexFriend,
  },
  {
    title: "Wife",
    value: "Wife",
    icon: Wife,
  },
  {
    title: "Mistress",
    value: "Mistress",
    icon: Mistress,
  },
  {
    title: "Friend",
    value: "Friend",
    icon: Friend,
  },
  {
    title: "Best Friend",
    value: "Best Friend",
    icon: BestFriend,
  },
  {
    title: "Step Sister",
    value: "Step Sister",
    icon: StepSister,
  },
  {
    title: "Step Mom",
    value: "Step Mom",
    icon: StepMom,
  },
];

const ChooseRelationship = ({ relationship, relationShipChoose, validationError }: IProps) => {
  const user = useUserStore((state: IUserState) => state.user);

  const onRelationShipChoose = (value: IPersonalityKey) => {
    amplitude.track(`Constructor Relationship Selected`, {
      userType: user.plan,
      parameters: [value.value],
    });

    relationShipChoose(value);
  };

  return (
    <div className={`${validationError?.relationship && styles.createAi_error}`}>
      <h4 className={styles.relationship_title}> Choose Relationship</h4>
      <div className={styles.relationship_container}>
        {RELATIONSHIPS.map((item, index) => (
          <RelationItem
            key={index}
            item={item}
            active={relationship}
            itemChoose={(value) => onRelationShipChoose(value)}
          />
        ))}
      </div>
    </div>
  );
};

interface IPersonalityProps {
  item: IRelationship;
  active: IPersonalityKey;
  itemChoose: (data: IPersonalityKey) => void;
}

const RelationItem = ({ item, active, itemChoose }: IPersonalityProps) => {
  return (
    <div
      className={`${styles.relationship_item} ${active.value === item.value ? styles.relationship_active : ""}`}
      onClick={() => itemChoose({ value: item.value, icon: item?.icon || "" })}
    >
      <div className={styles.relationship_wrapper}>
        <img src={item.icon} alt={item.title} />
      </div>
      <div className={styles.relationship_item_badge}>{item.title}</div>
    </div>
  );
};

export default ChooseRelationship;
