import styles from "./styles_ethnicity.module.css";

import Caucasian from "@assets/constructor/ethnicity/caucasian.png";
import Latina from "@assets/constructor/ethnicity/latina.png";
import Asian from "@assets/constructor/ethnicity/asian.png";
import Arab from "@assets/constructor/ethnicity/arab.png";
import Afro from "@assets/constructor/ethnicity/afro.png";

import EyeBrown from "@assets/constructor/ethnicity/eye_brown.png";
import EyeBlue from "@assets/constructor/ethnicity/eye_blue.png";
import EyeGreen from "@assets/constructor/ethnicity/eye_green.png";
import { IPersonalityKey } from "@shared/interfaces";
import * as amplitude from "@amplitude/analytics-browser";
import { IUserState, useUserStore } from "@entities/user";
import { useEffect } from "react";

interface IProps {
  age: string;
  eye: IPersonalityKey;
  ethnicity: IPersonalityKey;
  ethnicityChoose: (data: IPersonalityKey) => void;
  ageChoose: (data: string) => void;
  eyeChoose: (value: IPersonalityKey) => void;
  validationError: any;
}

interface IPersonality {
  title: string;
  value: string;
  icon?: string;
}

const ETHNICITY: IPersonality[] = [
  {
    title: "Caucasian",
    value: "Caucasian",
    icon: Caucasian,
  },
  {
    title: "Latina",
    value: "Latina",
    icon: Latina,
  },
  {
    title: "Asian",
    value: "Asian",
    icon: Asian,
  },
  {
    title: "Arab",
    value: "Arab",
    icon: Arab,
  },
  {
    title: "Black/Afro",
    value: "Black/Afro",
    icon: Afro,
  },
];

const AGE: Pick<IPersonality, "title" | "value">[] = [
  {
    title: "Teen(18+)",
    value: "18",
  },
  {
    title: "20s",
    value: "20",
  },
  {
    title: "30s",
    value: "30",
  },
  {
    title: "40-45",
    value: "40",
  },
];

const EYE: IPersonality[] = [
  {
    title: "Brown",
    value: "Brown",
    icon: EyeBrown,
  },
  {
    title: "Blue",
    value: "Blue",
    icon: EyeBlue,
  },
  {
    title: "Green",
    value: "Green",
    icon: EyeGreen,
  },
];

const ChooseEthnicity = ({ ethnicity, age, eye, ethnicityChoose, ageChoose, eyeChoose, validationError }: IProps) => {
  const user = useUserStore((state: IUserState) => state.user);

  useEffect(() => {
    amplitude.track(`Constructor Page Shown`);
  }, []);

  const onEthnicityClick = (value: IPersonalityKey) => {
    amplitude.track(`Constructor Ethnicity Selected`, {
      userType: user.plan,
      parameters: [value.value],
    });

    ethnicityChoose(value);
  };

  const onEyeClick = (value: IPersonalityKey) => {
    amplitude.track(`Constructor Eyes Color Selected`, {
      userType: user.plan,
      parameters: [value.value],
    });

    eyeChoose(value);
  };

  const onAgeClick = (value: string) => {
    amplitude.track(`Constructor Age Selected`, {
      userType: user.plan,
      parameters: [value],
    });

    ageChoose(value);
  };

  return (
    <div>
      <div className={`${validationError?.ethnicity && styles.createAi_error}`}>
        <h4 className={styles.ethnicity_title}>Choose Ethnicity</h4>
        <div className={styles.ethnicity_container}>
          {ETHNICITY.map((item, index) => (
            <EthnicityItem
              key={index}
              item={item}
              ethnicity={ethnicity}
              ethnicityChoose={(value) => onEthnicityClick(value)}
            />
          ))}
        </div>
      </div>

      <div className={`${validationError?.age && styles.createAi_error}`}>
        <h4 className={styles.ethnicity_title}>Choose Age</h4>
        <div className={styles.ai_age_container}>
          {AGE.map((item, index) => (
            <AgeItem key={index} item={item} age={age} ageChoose={(value) => onAgeClick(value)} />
          ))}
        </div>
      </div>

      <div className={`${validationError?.eyes_color && styles.createAi_error}`}>
        <h4 className={styles.ethnicity_title}>Choose Eyes Color</h4>
        <div className={styles.eyes_container}>
          {EYE.map((item, index) => (
            <EyeItem key={index} item={item} eye={eye} eyeChoose={(value) => onEyeClick(value)} />
          ))}
        </div>
      </div>
    </div>
  );
};

interface IEthnicityProps {
  item: IPersonality;
  ethnicity: IPersonalityKey;
  ethnicityChoose: (data: IPersonalityKey) => void;
}

const EthnicityItem = ({ item, ethnicity, ethnicityChoose }: IEthnicityProps) => {
  return (
    <div
      className={`${styles.ethnicity_item} ${ethnicity.value === item.value && styles.ethnicity_active}`}
      onClick={() => ethnicityChoose({ value: item.value, icon: item?.icon || "" })}
    >
      <img src={item.icon} alt="Caucasian" />
      <div className={styles.ethnicity_badge}>{item.title}</div>
    </div>
  );
};

interface IAgeProps {
  item: IPersonality;
  age: string;
  ageChoose: (data: string) => void;
}

const AgeItem = ({ item, age, ageChoose }: IAgeProps) => {
  return (
    <div
      className={`${styles.ai_age_item} ${age === item.value ? styles.ai_age_item_active : ""}`}
      onClick={() => ageChoose(item.value)}
    >
      {item.title}
    </div>
  );
};

interface IEyeProps {
  item: IPersonality;
  eye: IPersonalityKey;
  eyeChoose: (data: IPersonalityKey) => void;
}

const EyeItem = ({ item, eye, eyeChoose }: IEyeProps) => {
  return (
    <div
      className={`${styles.eye_item} ${eye.value === item.value ? styles.eye_active : ""}`}
      onClick={() => eyeChoose({ value: item.value, icon: item?.icon || "" })}
    >
      <div className={styles.eye_wrapper}>
        <img src={item.icon} alt={item.title} />
      </div>
      <div className={styles.eye_color}>{item.title}</div>
    </div>
  );
};

export default ChooseEthnicity;
